import React from 'react'
import CountUp from 'react-countup'

export default function CountingColumn({
  label,
  stat,
  isLastItem,
  isFirstItem,
}) {
  return (
    <div className="flex justify-center">
      <div
        className={`w-max ${
          isLastItem ? '' : 'border-b-2'
        } border-boynton-300 py-responsive-xl-48 text-center md:w-full ${
          !isLastItem ? 'md:border-r-2' : ''
        } md:border-b-0 md:py-px md:px-responsive-xl-28`}
      >
        <p className="headline-sm content text-[4rem] font-light" />
        <div className="min-h-[100px] text-[4rem] font-[300]">
          {isFirstItem && Number(stat) ? (
            <CountUp
              enableScrollSpy
              start={Number(stat) > 300 ? Number(stat) - 300 : Number(stat)}
              end={Number(stat)}
              useEasing={false}
              duration={5} // Seconds to complete 500 count
            />
          ) : (
            <p>{stat}</p>
          )}
        </div>
        <p className="text-xl">{label}</p>
      </div>
    </div>
  )
}

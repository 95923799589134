// src/pages/index.js

import React, { useEffect, useState, useRef } from 'react'
import { Link, graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../components/Layout.js'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropType from 'prop-types'
import Tab from '../components/Tab.js'
import TabPanel from '../components/TabPanel.js'
import CountingColumn from '../components/CountingColumn.js'
import parse from 'html-react-parser'
import { useLocation } from '@reach/router'
import { calculateDaysPassedSince } from '../utils/dateUtils.js'

const TAB_COLORS = ['#5cc4b1', '#3e56d2', '#662c90', '#b22fc7']

function IndexPage({ data }) {
  const [tabToggle, setTabToggle] = useState(true)
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedTabTitle, setSelectedTabTitle] = useState('')
  const location = useLocation()
  let currentHash = null

  useEffect(() => {
    if (location.hash) {
      currentHash = location.hash.substring(1)

      for (let i = 0, len = tabValues.length; i < len; i++) {
        if (tabValues[i].slug === currentHash) {
          setSelectedTab(i)

          setSelectedTabTitle(tabValues[i].title)
          tabValues[i].ref.current.focus()
        }
      }
    } else {
      setSelectedTabTitle(tabValues[selectedTab].title)
    }
  }, [currentHash, location])

  const { hero, impact, testimonial } = data.wpPage.template.homePageNew
  const tabValues = hero.tabsContent.map((tab, index) => ({
    id: tab.id,
    title: tab.title,
    color: TAB_COLORS[index],
    ref: useRef(null),
    slug: titleToUrlSlug(tab.title),
  }))
  const tabsData = hero.tabsContent.map(tab => ({
    id: tab.id,
    description: tab.solutionContent.subHero.description,
    imageSrc: getImage(tab.solutionContent.subHero.image.localFile) || null,
  }))

  function titleToUrlSlug(title) {
    return (
      title
        // Convert to lowercase
        .toLowerCase()
        // Replace spaces and "+" with a hyphen
        .replace(/\s+/g, '-')
        .replace(/\++/g, '-')
        // Remove multiple hyphens (if any)
        .replace(/-+/g, '-')
        // Remove leading and trailing hyphens
        .replace(/^-+|-+$/g, '')
    )
  }

  const handleClick = function (index) {
    setSelectedTab(index)
    setSelectedTabTitle(tabValues[index].title)
    setTabToggle(!tabToggle)

    window.location.hash = tabValues[index].slug
  }

  const parseCardTitle = domNode => {
    // Example: Replacing <i> tags with <em>
    if (domNode.name === 'i') {
      return <em>{domNode.children[0].data}</em>
    }
    if (domNode.name === 'strong') {
      return <span className="font-bold">{domNode.children[0].data}</span>
    }
    if (domNode.name === 'a') {
      return (
        <a
          className="font-bold text-[#158CEA] no-underline hover:text-[#158CEA]"
          {...domNode.attribs}
        >
          {domNode.children[0].data}
        </a>
      )
    }
  }

  // Custom function to handle navigation and scroll
  const handleNavigation = () => {
    window.scrollTo(0, 0) // Scroll to the top of the page
  }

  // Calculate the current number of incidents addressed
  const baseNumber = 8500
  const startDate = '2024-07-06'
  const incrementPerDay = 7
  const daysPassed = calculateDaysPassedSince(startDate)
  const updatedIncidentCount = baseNumber + daysPassed * incrementPerDay

  return (
    <Layout>
      <Seo post={data.wpPage} />

      {/* Hero */}
      <section className="container  py-responsive-xl-36 text-center">
        <h1 className="headline-lg text-boynton-400">{hero.title}</h1>
        <div className="px-responsive-xl-80 md:px-responsive-xl-128">
          <p className="mt-responsive-xl-8 text-lg">{hero.description}</p>
        </div>
      </section>

      {/* Tabs */}
      <section className="container tab-wrapper !mt-0">
        <div className="bg-red-50 p-20">
          <button
            onClick={() => setTabToggle(!tabToggle)}
            aria-expanded={!tabToggle}
            style={{
              backgroundColor: tabValues[selectedTab].color,
            }}
            className="block w-full bg-boynton-a11y p-responsive-xl-24 text-md text-white md:hidden"
          >
            {selectedTabTitle || tabValues[0].title}
            <span
              className={`ml-responsive-xl-8 inline-block origin-center ${
                tabToggle ? '-rotate-90' : 'rotate-0'
              }`}
            >
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.09985 1.5L5.09985 5.5L9.09985 1.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
          <ul
            role="tablist"
            aria-label="Solutions Tabs"
            className={`flex-col justify-between bg-[#041d44] text-center text-white md:flex-row md:border-b-[4px] ${
              tabToggle ? 'hidden md:flex' : 'flex'
            }`}
            style={{
              borderColor: tabValues[selectedTab].color,
            }}
          >
            {tabValues.map((node, index) => {
              const currentColor = index === selectedTab
              const selectedStyle = currentColor
                ? {
                    backgroundColor: node.color,
                    border: '1px',
                    borderBottomColor: node.color,
                  }
                : {}
              return (
                <Tab
                  id={node.slug}
                  tabPanelId={`${node.slug}-panel`}
                  index={index}
                  key={index}
                  handleChange={handleClick}
                  selectedTab={selectedTab}
                  title={node.title}
                  tabRef={node.ref}
                  classes={`w-full text-center p-responsive-xl-8`}
                  parentClasses="w-full text-center"
                  style={selectedStyle}
                />
              )
            })}
          </ul>

          {tabsData.map((tab, index) => {
            return (
              <TabPanel
                id={`${tab.id}-panel`}
                key={index}
                tabId={tab.id}
                tabIndex={index}
                selectedTab={selectedTab}
                className="bg-noise relative space-y-responsive-xl-128 bg-white pt-responsive-xl-40"
              >
                <section className="flex flex-col gap-responsive-xl-20 px-responsive-xl-16 lg:flex-row">
                  <div className="flex-1">
                    <p>{parse(tab.description, { replace: parseCardTitle })}</p>
                  </div>
                  <div className="flex-1">
                    {tab.imageSrc && (
                      <GatsbyImage image={tab.imageSrc} alt={tab.title} />
                    )}
                  </div>
                </section>
              </TabPanel>
            )
          })}
        </div>
      </section>

      {/* Full width container */}
      <section className="bg-[#021d44] px-responsive-xl-60 py-responsive-xl-48 text-center md:px-responsive-xl-80">
        <div className="container flex flex-col gap-responsive-xl-20">
          <h2 className="font-bold text-boynton-400 md:px-responsive-xl-100">
            {impact.title}
          </h2>
          <p className="px-responsive-xl-28 text-white md:px-responsive-xl-160">
            {impact.description}
          </p>
        </div>
      </section>

      {/* Counting Columns */}
      <section className="container font- flex flex-col justify-center text-[#188bee] md:flex-row">
        {impact.statistics.map((stat, index) => {
          const isCyberIncidents = index === 0 // TODO: We need to revise this logic to enhance its efficiency and ensure all cards are dynamic. Additionally, we must add a few fields on the backend only.
          const statValue = isCyberIncidents ? updatedIncidentCount : stat.stat
          return (
            <CountingColumn
              key={`${stat.description}--${index}`}
              label={stat.description}
              isFirstItem={index === 0}
              // stat={stat.stat}
              stat={statValue}
              isLastItem={impact.statistics.length === index + 1}
            />
          )
        })}
      </section>

      {/* Cards */}
      <section className="container grid gap-responsive-xl-24 text-white md:grid-cols-2">
        {impact.cards.map((card, index) => (
          <div
            key={`${card.title}--${index}`}
            style={{
              backgroundImage: `url(${card.backgroundImage.sourceUrl})`,
              objectFit: 'fill',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            className="relative flex"
          >
            <div className="flex flex-col justify-between gap-responsive-xl-12 p-responsive-xl-24">
              <div className="flex flex-col gap-y-responsive-xl-12">
                <span className="headline-md font-light">
                  {parse(card.title, {
                    replace: parseCardTitle,
                  })}
                </span>
                <p>{card.description}</p>
              </div>
              {/* // Adjusted target code with custom navigation and scroll-to-top */}
              {card?.buttonLink?.target &&
              card.buttonLink?.target === '_blank' ? (
                <a
                  href={card.buttonLink.url}
                  target={card.buttonLink.target}
                  className="btn btn-white z-10 w-max self-end p-responsive-xl-8 capitalize text-fuscia-full"
                >
                  {card.buttonText}
                </a>
              ) : (
                <Link
                  to={card.buttonLink.url}
                  onClick={handleNavigation}
                  className="btn btn-white z-10 w-max self-end p-responsive-xl-8 capitalize text-fuscia-full"
                >
                  {card.buttonText}
                </Link>
              )}
            </div>
          </div>
        ))}
      </section>

      <section
        className="container py-responsive-xl-40"
        style={{
          borderColor: '#97cbf6',
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
        }}
      >
        <div className="px-responsive-xl-80">
          <p className="headline-sm text-center  italic">{`${testimonial.content}`}</p>
        </div>
        {testimonial.author && (
          <p className="mt-responsive-xl-8 text-center font-bold md:text-end">
            – {testimonial.author}
          </p>
        )}
      </section>
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropType.object,
}

export const query = graphql`
  query {
    wpPage(isFrontPage: { eq: true }) {
      seo {
        ...SeoFragment
      }
      template {
        templateName
        ... on WpTemplate_HomePageNew {
          templateName
          homePageNew {
            hero {
              description
              title
              tabsContent {
                ... on WpSolution {
                  id
                  title
                  solutionContent {
                    subHero {
                      description
                      image {
                        localFile {
                          url
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            impact {
              title
              description
              statistics {
                symbol
                stat
                description
                fieldGroupName
              }
              cards {
                buttonLink {
                  url
                  title
                  target
                }
                buttonText
                description
                title
                backgroundImage {
                  sourceUrl
                }
              }
            }
            testimonial {
              author
              content
            }
          }
        }
      }
    }
  }
`
